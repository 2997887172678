// 注意，顶级菜单如统计，数据库改了之后，这里也要同步修改，children内的可以不管
export const menus = [
    {
        title: "首页",
        sort: 99,
        name: "homePage",
        icon: "ri-home-4-line",
        path: "/home",
        component: "",
        children: [],
    },
    {
        title: "统计",
        sort: 98,
        name: "statistic",
        icon: "ri-line-chart-line",
        path: "/statistic",
        component: "",
        children: [
            {
                title: "访问记录",
                name: "accessRecord",
                path: "/statistic/accessRecord",
            },
            // {
            //     title: "租金统计",
            //     name: "rentSurvey",
            //     path: "/statistic/rentSurvey",
            // },
            {
                title: "出勤统计",
                name: "attendance",
                path: "/statistic/attendance",
            },
            {
                title: "存证列表",
                name: "evidence",
                path: "/statistic/evidence",
            },
            {
                title: "车辆出租率统计",
                name: "carsend",
                path: "/statistic/carsend",
            },
            {
                title: "订单统计",
                name: "statisticorder",
                icon: "ri-computer-line",
                path: "/statistic/order",
                component: "",
                children: [
                    {
                        title: "自动分配记录",
                        name: "orderauto",
                        path: "/statistic/order/auto",
                    },
                    {
                        title: "订单统计",
                        name: "orderorder",
                        path: "/statistic/order/order",
                    },
                    {
                        title: "未结清订单",
                        name: "ordersett",
                        path: "/statistic/order/sett",
                    },
                ],
            },
        ],
    },
    {
        title: "财务统计",
        sort: 97,
        name: "finance",
        icon: "ri-line-chart-line",
        path: "/finance",
        component: "",
        children: [
            {
                title: "按揭表",
                name: "mortgage",
                path: "/finance/mortgage",
            },
            {
                title: "营收表",
                name: "revenue",
                path: "/finance/revenue",
            },
            {
                title: "收入统计",
                name: "income",
                icon: "ri-computer-line",
                path: "/finance/income",
                component: "",
                children: [
                    {
                        title: "租金收入",
                        name: "rent",
                        path: "/finance/income/rent",
                    },
                    {
                        title: "事故收入",
                        name: "accident",
                        path: "/finance/income/accident",
                    },
                    {
                        title: "残值收入",
                        name: "residual",
                        path: "/finance/income/residual",
                    },
                ],
            },
            {
                title: "支出统计",
                name: "expenditure",
                icon: "ri-computer-line",
                path: "/finance/expenditure",
                component: "",
                children: [
                    {
                        title: "维修支出",
                        name: "wx",
                        path: "/finance/expenditure/wx",
                    },
                    {
                        title: "保养支出",
                        name: "by",
                        path: "/finance/expenditure/by",
                    },
                    {
                        title: "保险支出",
                        name: "bx",
                        path: "/finance/expenditure/bx",
                    },
                    {
                        title: "利息支出",
                        name: "interest",
                        path: "/finance/expenditure/interest",
                    },
                    {
                        title: "营销支出",
                        name: "marketing",
                        path: "/finance/expenditure/marketing",
                    },
                    {
                        title: "违章支出",
                        name: "wz",
                        path: "/finance/expenditure/wz",
                    },
                ],
            },
        ],
    },
    {
        title: "车辆管理",
        sort: 96,
        name: "car",
        path: "/car",
        icon: "ri-car-line",
        component: "",
        children: [
            {
                title: "车辆排期表",
                name: "scheduling",
                path: "/car/scheduling",
            },
            {
                title: "车系列表",
                name: "carSeries",
                path: "/car/carSeries",
            },
            {
                title: "车辆列表",
                name: "carList",
                path: "/car/carList",
            },
            {
                title: "待租车辆",
                name: "readyCar",
                path: "/car/carReady",
            },
            {
                title: "商业险45天内",
                name: "carVciList",
                path: "/car/carVciList",
            },
            {
                title: "交强险45天内",
                name: "carTciList",
                path: "/car/carTciList",
            },
            {
                title: "年检90内",
                name: "carYciList",
                path: "/car/carYciList",
            },
            // {
            //     title: "待租车辆",
            //     name: "forRent",
            //     path: "/car/forRent",
            // },
            {
                title: "已处置车辆",
                name: "disposeList",
                path: "/car/disposeList",
            },
            {
                title: "二手车列表",
                name: "usedCar",
                path: "/car/usedCar",
            },
        ],
    },
    {
        title: "维保管理",
        sort: 95,
        name: "maintenance",
        path: "/maintenance",
        icon: "ri-tools-line",
        component: "",
        children: [
            {
                title: "待维修",
                name: "pendingRepair",
                path: "/maintenance/pendingRepair",
            },
            {
                title: "维修中",
                name: "underRepair",
                path: "/maintenance/underRepair",
            },
            {
                title: "待保养",
                name: "pendingMaintenance",
                path: "/maintenance/pendingMaintenance",
            },
            {
                title: "待大保养",
                name: "pendingMajorMaintenance",
                path: "/maintenance/pendingMajorMaintenance",
            },
            {
                title: "历史维修",
                name: "repairHistory",
                path: "/maintenance/repairHistory",
            },
            {
                title: "历史保养",
                name: "maintenanceHistory",
                path: "/maintenance/maintenanceHistory",
            },
        ],
    },
    {
        title: "报销管理",
        sort: 94,
        name: "reimburse",
        path: "/reimburse",
        icon: "ri-exchange-cny-line",
        component: "",
        children: [
            {
                title: "报销列表",
                name: "reimburseList",
                path: "/reimburse/list",
            },
        ],
    },
    {
        title: "违章管理",
        sort: 94,
        name: "violation",
        path: "/violation",
        icon: "ri-barricade-line",
        component: "",
        children: [
            {
                title: "违章列表",
                name: "violationList",
                path: "/violation/list",
            },
        ],
    },
    {
        title: "订单管理",
        sort: 93,
        name: "order",
        path: "/order",
        icon: "ri-bill-line",
        component: "",
        children: [
            {
                title: "小程序待确认",
                name: "pendingConfirmation",
                path: "/order/pendingConfirmation",
            },
            {
                title: "预订单",
                name: "pre-order",
                path: "/order/pre-order",
            },
            {
                title: "待送达",
                name: "pendingDelivery",
                path: "/order/pendingDelivery",
            },
            {
                title: "待取回",
                name: "pendingPickup",
                path: "/order/pendingPickup",
            },
            {
                title: "回车信息",
                name: "return",
                path: "/order/return",
            },
            {
                title: "待结算",
                name: "pendingSettlement",
                path: "/order/pendingSettlement",
            },
            {
                title: "未结清",
                name: "unpaid",
                path: "/order/unpaid",
            },
            {
                title: "已结算",
                name: "history",
                path: "/order/history",
            },
            {
                title: "改价确认",
                name: "priceChange",
                path: "/order/priceChange",
            },
            {
                title: "外调车列表",
                name: "externalTransfer",
                path: "/order/externalTransfer",
            },
        ],
    },
    {
        title: "客户管理",
        sort: 92,
        name: "customer",
        path: "/customer",
        icon: "ri-user-2-line",
        component: "",
        children: [
            {
                title: "客户列表",
                name: "customerList",
                path: "/customer/list",
            },
            {
                title: "黑名单列表",
                name: "blacklist",
                path: "/customer/blacklist",
            },
            {
                title: "新增统计",
                name: "addstatis",
                path: "/customer/addstatis",
            },
        ],
    },
    {
        title: "小程序管理",
        sort: 91,
        name: "wxmp",
        path: "/wxmp",
        icon: "ri-smartphone-line",
        component: "",
        children: [
            {
                title: "广告列表",
                name: "advertising",
                path: "/wxmp/advertising",
            },
            {
                title: "套餐列表",
                name: "package",
                path: "/wxmp/package",
            },
            {
                title: "素材列表",
                name: "resources",
                path: "/wxmp/resources",
            },
            {
                title: "评论列表",
                name: "comment",
                path: "/wxmp/comment",
            },
            {
                title: "优惠券列表",
                name: "coupon",
                path: "/wxmp/coupon",
            },
        ],
    },
    {
        title: "系统管理",
        sort: 90,
        name: "system",
        path: "/system",
        icon: "ri-settings-4-line",
        component: "",
        children: [
            {
                title: "职务设置",
                name: "job",
                path: "/system/job",
            },
            {
                title: "部门列表",
                name: "department",
                path: "/system/department",
            },
            {
                title: "员工列表",
                name: "employee",
                path: "/system/employee",
            },
            {
                title: "其他配置",
                name: "settings",
                path: "/system/settings",
            },
            {
                title: "权限列表",
                name: "permissions",
                path: "/system/permissions",
            },
        ],
    },
];