<template>
<!-- v-loading="loading" -->
    <el-table
        width="100%"
        height="100%"
        :row-key="rowKey"
        :border="border"
        :stripe="stripe"
        :fit="fit"
        :data="data"
        :show-header="showHeader"
        @row-click="handleTableRowClick"
        @row-dblclick="handleTableRowDbClick"
    >
        <component is="el-table-column"
            v-for="(item,index) in columns"
            :key="index"
            :type="item?.type"
            :prop="item.field"
            :label="item.label"
            :align="item?.align ?? 'center'"
            :show-overflow-tooltip="item?.ellipsis ?? false"
            :width="item.width"
            :fixed="item.comp === 'actions' ? 'right' : false"
        >
            <template v-if="item.comp === 'checkbox'" #default="scope">
                <el-checkbox v-model="scope.row[item.field]" :disabled="item.selectableFun(scope.row)" />
            </template>
            <template v-if="item.comp === 'image'" #default="scope">
                <div class="flex center-v center-h">
                    <el-image
                        v-if="scope.row[item.field]"
                        preview-teleported
                        :style="{height: item.imgh+'px'}"
                        :src="scope.row[item.field]"
                        :preview-src-list="[scope.row[item.field]]" />
                </div>
            </template>
            <template v-if="item.comp === 'images'" #default="scope">
                <div class="flex flex-warp" v-if="getImgList(scope.row[item.field]).length">
                    <el-image
                        v-if="item.isOne"
                        preview-teleported
                        :style="{height: item.imgh+'px', width: item.imgw+'px', marginRight: '5px', marginBottom: '5px'}"
                        :src="getImgList(scope.row[item.field])[0]"
                        :initial-index="0"
                        :preview-src-list="getImgList(scope.row[item.field])" />
                    <el-image
                        v-else
                        v-for="img,i in getImgList(scope.row[item.field])"
                        preview-teleported
                        :style="{height: item.imgh+'px', width: item.imgw+'px', marginRight: '5px', marginBottom: '5px'}"
                        :key="i"
                        :initial-index="i"
                        :src="img"
                        :preview-src-list="getImgList(scope.row[item.field])" />
                </div>
                <div v-else></div>
            </template>
            <template v-if="item.comp === 'avatar'" #default="scope">
                <div class="flex center-v center-h">
                    <el-image
                        v-if="scope.row.avatar"
                        preview-teleported
                        :style="{height: item.imgh+'px', marginRight: '6px', borderRadius: '50%'}"
                        :src="scope.row.avatar"
                        :preview-src-list="[scope.row.avatar]" />
                    <span>{{ scope.row[item.field] }}</span>
                </div>
            </template>
            <template v-if="item.comp === 'arrayId'" #default="scope">
                <template v-for="(action,aindex) in item.options" :key="aindex">
                    <span v-if="scope.row[item.field]?.some(i=>i==action.value)" :style="{color: action.color ? action.color : '#333'}">{{ action.label }}、</span>
                </template>
            </template>
            <template v-if="item.comp === 'arrayValue'" #default="scope">
                <template v-for="(action,aindex) in item.options" :key="aindex">
                    <span v-if="scope.row[item.field] == action.value" :class="action.color" :style="action.style">{{ action.label }}</span>
                </template>
                <span v-if="!scope.row[item.field]">{{ item.default }}</span>
            </template>
            <template
                v-if="item.type === 'index'"
                #default="scope">
                <span>{{ (page-1)*size + scope.$index + 1 }}</span>
            </template>
            <template v-if="item.comp === 'switch'" #default="scope">
                <div v-if="hasRule(item.rule)">
                <span @click.stop="handleSwitchChange(scope.row)">
                    <el-switch
                        v-model="scope.row[item.field]"
                        :active-value="item.option ? item.option['active-value'] : 1"
                        :inactive-value="item.option ? item.option['inactive-value'] : -1" />
                </span>
                </div>
                <div v-else>
                    {{item.optionObj[scope.row[item.field]]}}
                </div>
            </template>
            <template v-if="item.comp === 'selection'" #default="scope">
                <span v-if="item.colors" :style="{color: item.colors[scope.row[item.field]]}">
                    {{item.options[scope.row[item.field]]}}
                </span>
                <span v-else>
                    {{item.options[scope.row[item.field]]}}
                </span>
            </template>
            <template v-if="item.comp === 'actions'" #default="scope">
                <template v-for="(action,aindex) in item.options" :key="aindex">
                    <el-button
                        v-if="hasRule(action.rule) && (!action.showFun || action.showFun(scope.row))"
                        :type="action.type"
                        :size="action.size || 'small'"
                        style="margin-bottom: 5px;"
                        @click.stop="handleActionButtonClick(action.action, scope.row, scope.$index)">
                        {{ action.title }}
                    </el-button>
                </template>
            </template>
            <template v-if="item.comp === 'ellipsis'" #default="scope">farrayId
                <div class="ellipsis">{{ scope.row[item.field] }}</div>
            </template>
            <template v-if="item.comp === 'level'" #default="scope">
                <div class="ellipsis">
                    <CustomerLevel :num="scope.row[item.field]" />
                </div>
            </template>
            <template v-if="item.comp === 'department_id' || item.comp === 'department'" #default="scope">
                <div class="ellipsis">
                    {{ showDepartment(scope.row[item.field]) }}
                </div>
            </template>
            <template v-if="item.comp === 'dayPrice'" #default="scope">
                <div class="ellipsis">
                    {{ showDayPrice(scope.row[item.field]) }}
                </div>
            </template>
            <template v-if="item.comp === 'day'" #default="scope">
                <div class="ellipsis">
                    {{ scope.row[item.field] }}天{{ scope.row[item.hour]<0?0:scope.row[item.hour] }}小时
                </div>
            </template>
            <template v-if="item.comp === 'hh'" #default="scope">
                <div class="hh">
                    {{ scope.row[item.field] }}
                </div>
            </template>
            <template v-if="item.comp === 'text'" #default="scope">
                <div v-if="item.default && !getPropertyByPath(scope.row, item.field) && (!item.showFun || item.showFun(scope.row))" class="default" :class="item.ellipsis ? 'ellipsis' : ''">{{scope.row.post_id == 1 ? "" : item.default}}</div>
                <div :class="item.ellipsis ? 'ellipsis' : ''" v-if="!item.showFun || item.showFun(scope.row)" :style="{color: setThemeColor(item, scope.row[item.field], scope.row)}">
                    <span v-if="item.with_department_name && scope.row[item.field]">{{ showDepartment(scope.row[item.with_department_name]) }}：</span><span :style="{color: item.color}">{{ getPropertyByPath(scope.row, item.field) }}</span><span v-if="item.with_source" class="yes">（{{ showSource(scope.row) }}）</span>
                </div>
                <div v-else></div>
            </template>
            <template #default="scope">
                <slot name="custom" :row="scope.row" :comp="item.comp"></slot>
            </template>
        </component>
    </el-table>
    <div
        v-if="total"
        class="flex end pt-10">
        <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :page-size="size"
            :total="total"
            :page-sizes="[5, 10, 20, 50, 100, 200, 300, 400, 500]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>
<script setup>
import {ElMessageBox} from "element-plus";
import { StarFilled } from '@element-plus/icons-vue';

import {hasRule} from "@/utils/tools";

const props = defineProps({
    rowKey: {type: String, default: "id"},
    deleteTips: {type: String, default: ""},
    stripe: {type: Boolean, default: false},
    border: {type: Boolean, default: true},
    fit: {type: Boolean, default: true},
    showHeader: {type: Boolean, default: true},
    loading: {type: Boolean, default: false},
    columns: {type: Array, default: []},
    data: {type: Array, default: []},
    departmentList: {type: Array, default: []},
    total: {type: Number, default: 0},
    size: {type: Number, default: 10},
    page: {type: Number, default: 1},


    selectedKeys: {type: Array, default: []},
    selectedRows: {type: Array, default: []},
});
const emits = defineEmits([
    "rowClick",
    "rowDbClick",
    "switchChange",
    "actionButtonClick",
    "pageChange",
    "sizeChange",
]);

const getImgList = (val) => {
    if (Array.isArray(val)) {
        return val;
    } else {
        const valObj = JSON.parse(val);
        if (Array.isArray(valObj)) {
            return valObj;
        } else {
            return Object.values(valObj).filter(i => i) || [];
        }
    }
}
// 设置主题色
const setThemeColor = (item, value, row) => {
    if (item.theme_color && props.departmentList?.length) {
        return props.departmentList.find(i => i[item.theme_color_key] == value)?.color || '#333';
    } else if (item.with_department_name && props.departmentList?.length) {
        return props.departmentList.find(i => i.value == row[item.with_department_name])?.color || '#333';
    } else {
        return '#333';
    }
}
// 表格单击行
const handleTableRowClick = (row, column) => {
    console.log("handleTableRowClick");
    emits("rowClick", row, column);
};

// 表格双击行
const handleTableRowDbClick = (row, column) => {
    console.log("handleTableRowDbClick");
    emits("rowDbClick", row, column);
};

// 开关
const handleSwitchChange = (row) => {
    console.log("handleSwitchChange", row);
    emits("switchChange", row);
};

// 操作按钮事件
const handleActionButtonClick = (act, row, index) => {
    console.log("handleActionButtonClick", act, row, index);
    if (act === "delete") {
        ElMessageBox.confirm(props.deleteTips?props.deleteTips:"确定要删除这条数据吗？","提示",{type: 'warning'})
            .then(() => {
                emits("actionButtonClick", act, row, index);
            }).catch(() => {
        });
    } else {
        emits("actionButtonClick", act, row, index);
    }
};

// 翻页
const handleCurrentChange = (page) => {
    emits("pageChange", page);
};

// 选页
const handleSizeChange = (size) => {
    emits("sizeChange", size);
};

// 获取对象属性值 多级属性'a.b' = obj.a.b
const getPropertyByPath = (object, propertyPath) => {
    let properties = propertyPath.split(".");
    let value = object;
    for (let property of properties) {
        if (value) {
            value = value[property];
        }
    }
    return value;
}
// 获取部门信息
const showDepartment = (val) => {
    if (props.departmentList?.length) {
        return props.departmentList.find(i => i.value == val)?.title || props.departmentList.find(i => i.value == val)?.label || "";
    } else {
        return "";
    }
}
// 获取来源信息
const showSource = (row) => {
    if (row.openid) {
        return "小程序";
    } else if (row.customer) {
        return row.customer.source;
    } else if (row.wx_customer) {
        return row.wx_customer.source;
    } else if (row.phone_customer) {
        return row.phone_customer.source;
    }
}
// 获取日租金
const showDayPrice = (obj) => {
    if (obj) {
        return `￥${(parseFloat(obj.base_price) + parseFloat(obj.brokerage) + parseFloat(obj.minimum_price)).toFixed(2)}/天`;
    } else {
        return `￥${0.00}/天`;
    }
}
</script>