import axios from "axios";
import Router from "@/router";
import {getItem} from "./storage";
import {elMsg} from "@/utils/message";
import { hideLoading, translateRequest } from "./loading";
import store from '@/store'

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 1000 * 60
});

http.interceptors.request.use(
    config => {
        config.headers["authorization"] = getItem("TOKEN") || "";
        translateRequest(config);
        return config;
    },
    err => {
        hideLoading();
        Promise.reject(err);
    }
);

http.interceptors.response.use(
    res => {
        hideLoading();
        // console.log('response',res);
        if (res.status === 200 || res.data.code === 200) {
            return Promise.resolve(res.data);
        } else {
            // if (res.data.code === 2001) {
            //     Router.replace("/login");
            // }
            elMsg(res.data.message, 'error');
            return Promise.reject(res.data);
        }
    },
    err => {
        hideLoading();
        if (err.response.data.code === 401) {
            elMsg(err.response.data.data ?? "登录已失效，请重新登录", "error", 1500);
            setTimeout(() => {
                store.commit("system/clearAll");
                Router.replace("/login");
            }, 1500)
            return Promise.reject(err);
        } else {
            elMsg(err.response.data.message ?? '服务器错误！', "error");
            return Promise.reject(err);
        }
    }
);

export default http;
