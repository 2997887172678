<template>
    <div class="flex center-v gap wrap">
        <el-tag
            v-for="tag in tagsRef"
            :key="tag"
            :closable="closable && !disabled"
            :disable-transitions="true"
            @close="handleClose(tag)"
        >
            {{ tag }}
        </el-tag>
        <div v-if="newable && (max > 0 ? tagsRef.length < max : true)">
            <el-input
                v-if="inputVisible"
                ref="inputRef"
                v-model="inputValue"
                class="w-12"
                size="small"
                @keyup.enter="handleInputConfirm"
                @blur="handleInputConfirm"
            />
            <el-button v-else-if="!disabled" size="small" @click="showInput">
                {{addbtn}}
            </el-button>
        </div>
    </div>
</template>
<script setup>
import {computed, nextTick, ref} from "vue";
import {cloneDeep} from "lodash";

const props = defineProps({
    modelValue: {type: Array, default: []},
    closable: {type: Boolean, default: false},
    newable: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    addbtn: {type: String, default: "New Tag"},
    max: {type: Number, default: 0},
});
const emits = defineEmits([
    "update:modelValue",
]);

const tagsRef = computed({
    get: () => props.modelValue,
    set: (val) => {
        emits("update:modelValue", val)
    }
})
const inputRef = ref();
const inputVisible = ref(false);
const inputValue = ref("");
const showInput = () => {
    inputVisible.value = true;
    nextTick(() => {
        inputRef.value.focus();
    });
}
const handleInputConfirm = () => {
    if (inputValue.value) {
        // tagsRef.value.push(inputValue.value);
        tagsRef.value = [...tagsRef.value, inputValue.value];
    }
    inputVisible.value = false;
    inputValue.value = "";
}
const handleClose = (tag) => {
    let temp = cloneDeep(tagsRef.value);
    let index = temp.indexOf(tag);
    if (index > -1) {
        temp.splice(index, 1);
        tagsRef.value = [...temp];
    }
}

</script>