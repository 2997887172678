import {createRouter, createWebHistory} from "vue-router";
import {makeAsyncRoutes} from '@/utils/asyncRoutes';
import Store from "@/store";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/layout'),
        redirect: '/home',
        children: [
            {
                path: '/profile',
                name: 'Profile',
                component: () => import('@/views/pages/profile'),
                meta: {
                    title: '个人资料',
                    location: ['个人资料'],
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login')
    },
    {
        path: '/norule',
        name: 'norule',
        component: () => import('@/views/pages/norule')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/pages/404'),
    },
    {
        path: '/:pathMatch(.*)',
        name: 'bad-not-found',
        component: () => import('@/views/pages/404'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    // 判断登录状态
    if (Store.getters['system/authenticated']) {
        // 添加tab
        if (to.meta.title) {
            Store.commit('system/addTabbar', {
                title: to.meta.title,
                name: to.name,
                path: to.path,
                query: to.query,
            })
        }
        // 添加breadcrumb
        if (to.meta.location && to.meta.location.length > 0) {
            Store.commit('system/setLocation', to.meta.location);
        }
        // 动态路由
        if (!Store.state.system.asyncRoutesMark) {
            makeAsyncRoutes().then(asyncRoutes => {
                asyncRoutes.map(route => {
                    router.addRoute('home', route);
                })
                Store.commit('system/setAsyncRoutesMark', true);
                if (asyncRoutes.some(i => i.path === to.path)) {
                    next({ path: to.path, query: to.query });
                } else if (asyncRoutes.length) {
                    next({ path: asyncRoutes[0].path, query: to.query });
                } else {
                    next({ path: '/norule', query: to.query });
                }
            })
        } else {
            next();
        }
    } else {
        if (to.name !== 'login') {
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next();
        }
    }
})

export default router;
