<template>
    <div>
        <el-upload
            ref="uploadRef"
            class="avatar-uploader"
            :class="{
                'noborder': filePath,
                'uploader-del-icon-hide': disabled,
            }"
            :action="store.state.oss.host"
            :data="ossToken"
            :accept="accept"
            :drag="drag"
            :limit="1"
            :multiple="false"
            :disabled="disabled"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-error="handleError"
        >
            <ul v-if="filePath" class="el-upload-list el-upload-list--picture-card">
                <li class="el-upload-list__item is-success" tabindex="0" style="margin:0">
                    <img class="el-upload-list__item-thumbnail" v-if="fileType" src="https://product.web.btzcgo.com/proimg/pdf.png" alt="">
                    <img class="el-upload-list__item-thumbnail" v-else :src="filePath" alt="">
                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click.stop="handlePreview">
                            <i class="el-icon el-icon--zoom-in">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                                    <path fill="currentColor" d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704m-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64z"></path>
                                </svg>
                            </i>
                        </span>
                        <span class="el-upload-list__item-delete" @click.stop="handleRemove">
                            <i class="el-icon el-icon--delete">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                                    <path fill="currentColor" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32zm448-64v-64H416v64zM224 896h576V256H224zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32m192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32"></path>
                                </svg>
                            </i>
                        </span>
                    </span>
                </li>
            </ul>
            <el-icon v-else class="avatar-uploader-icon">
                <Plus />
            </el-icon>
        </el-upload>
        <el-dialog v-model="visible" :width="preImgWidth">
            <iframe v-if="fileType" style="width: 100%;height: 600px;" :src="filePath" frameborder="0"></iframe>
            <img v-else width="100%" :src="filePath" alt="Preview Image" />
            <el-button class="mt-10" @click="handleDownload">下载</el-button>
        </el-dialog>
    </div>
</template>
<script setup>
import {ref, computed} from "vue";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";
import {Plus} from "@element-plus/icons-vue";
import {getOssToken, randomString} from "@/utils/tools";

const store = useStore();
const props = defineProps({
    modelValue: String,
    drag: {type: Boolean, default: false},
    preImgWidth: {type: Number, default: 400},
    disabled: {type: Boolean, default: false},
    accept: {type: String, default: "image/*"},
    listType: {type: String, default: "picture-card"},
    class: String,
    style: String,
});
const emits = defineEmits([
    "update:modelValue",
    "success",
    "remove",
    "fc.el",
]);
const ossToken = ref(store.state.oss.token);
const filePath = computed({
    get: () => props.modelValue,
    set: (val) => {
        emits("update:modelValue", val);
        return val;
    }
});
const fileType = computed(() => {
    return filePath.value?.slice(-3).toLocaleLowerCase() == 'pdf'
})
const tempPath = ref("");
const visible = ref(false);
const uploadRef = ref();

const beforeUpload = async (file) => {
    console.log(file);
    if (!store.getters["oss/hasToken"]) {
        await getOssToken();
    }
    if (store.getters["oss/hasToken"]) {
        ossToken.value = store.state.oss.token;
        ossToken.value.key = store.state.oss.token.dir + randomString() + file.name.slice(file.name.lastIndexOf("."));
        tempPath.value = store.state.oss.host + "/" + ossToken.value.key;
    }
    if (file.size > (1024 * 1024 * 10)) {
        ElMessage.error("上传图片大小不能超过 10MB!");
        return false;
    }
};
const handleSuccess = () => {
    filePath.value = tempPath.value
    emits("success", tempPath.value);
};
const handleRemove = () => {
    console.log("删除图片事件");
    filePath.value = "";
    tempPath.value = "";
    emits("success", "");
    uploadRef.value.clearFiles();
};
const handleError = () => {
    console.log("handleError");
    ElMessage.error("上传失败，请重试！");
};
const handlePreview = () => {
    visible.value = true;
};
const handleDownload = () => {
    if (filePath.value?.slice(-4).toLocaleLowerCase() == '.pdf') {
        window.open(filePath.value);
    } else {
        const link = document.createElement('a');
        link.href = filePath.value;
        link.download = '11111';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 100px;
    height: 100px;
    display: block;
}
</style>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color-darker);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}
.noborder .el-upload {
    border: none;
}
.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}
.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    text-align: center;
    background-color: var(--el-fill-color-lighter);
}
</style>