import { ElLoading } from "element-plus";

let loadingInstance;

export const translateRequest = (
  config
) => {
  if (!config.hideLoading) {
    showLoading();
  }
};

export const showLoading = (text = "加载中") => {
  loadingInstance = ElLoading.service({
    lock: true,
    text: text,
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.1)",
  });
};

export const hideLoading = () => {
  loadingInstance.close();
};
