<template>
    <div class="toolbar-wrapper">
        <!-- search form -->
        <div class="flex between" style="padding: 0 5px" v-if="showForm">
            <div :style="{width: formWidth}" class="flex">
                <form-create
                    v-if="searchRulesRef.length"
                    v-model:api="fApi"
                    :rule="searchRulesRef"
                    :option="searchOptions"
                />
                <slot name="form-append-start"></slot>
            </div>
            <div>
                <slot name="form-append"></slot>
            </div>
        </div>
        <!-- sort -->
        <div class="flex between" v-if="showSort">
            <div>
                <el-form
                    v-if="sortRules.length"
                    :inline="true">
                    <el-form-item :label="sortTitle" style="margin: 0">
                        <el-radio-group v-model="sortRef" @change="handleSortChange">
                            <el-radio
                                v-for="(item, index) in sortRules"
                                :key="index"
                                :value="item.value">
                                {{item.title}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <slot name="sort-append"></slot>
            </div>
        </div>
        <!-- sort2 -->
        <div class="flex between" v-if="showSort2">
            <div>
                <el-form
                    v-if="sortRules2.length"
                    :inline="true">
                    <el-form-item :label="sortTitle2" style="margin: 0">
                        <el-radio-group v-model="sortRef2" @change="handleSortChange2">
                            <el-radio
                                v-for="(item, index) in sortRules2"
                                :key="index"
                                :value="item.value">
                                {{item.title}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <slot name="sort-append"></slot>
            </div>
        </div>
        <!-- sort3 -->
        <div class="flex between" v-if="showSort3">
            <div>
                <el-form
                    v-if="sortRules3.length"
                    :inline="true">
                    <el-form-item :label="sortTitle3" style="margin: 0">
                        <el-radio-group v-model="sortRef3" @change="handleSortChange3">
                            <el-radio
                                v-for="(item, index) in sortRules3"
                                :key="index"
                                :value="item.value">
                                {{item.title}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <slot name="sort-append"></slot>
            </div>
        </div>
        <!-- tabs -->
        <div class="flex center-v pb-10">
            <div class="w-100">
                <el-tabs
                    v-if="tabsRules.length"
                    v-model="activeRef"
                    @tab-change="handleTabsChange"
                    style="max-width: 100%;display: inline-block">
                    <el-tab-pane
                        v-for="(tab, index) in tabsRules"
                        :key="index"
                        :label="tab.label + (tab?.num >= 0 ? '('+tab.num+')' : '')"
                        :name="tab.name" />
                </el-tabs>
            </div>
            <div class="grow flex end">
                <slot name="tabs-append"></slot>
            </div>
        </div>
        <!-- action -->
        <div>
            <slot name="actions"></slot>
        </div>
    </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";

const props = defineProps({
    tabsRules: {type: Array, default: []},
    sortRules: {type: Array, default: []},
    sortRules2: {type: Array, default: []},
    sortRules3: {type: Array, default: []},
    searchRules: {type: Array, default: []},
    active: {type: String, default: ""},
    sort: {type: [Number, String], default: 0},
    sort2: {type: [Number, String], default: 0},
    sort3: {type: [Number, String], default: 0},
    sortTitle: {type: String, default: ""},
    sortTitle2: {type: String, default: ""},
    sortTitle3: {type: String, default: ""},
    formWidth: {type: String, default: "auto"},
    showForm: {type: Boolean, default: true},
    showSort: {type: Boolean, default: true},
    showSort2: {type: Boolean, default: true},
    showSort3: {type: Boolean, default: true},
});
const emits = defineEmits([
    "update:active",
    "update:sort",
    "update:sort2",
    "update:sort3",
    "tabsChange",
    "sortChange",
    "sortChange2",
    "sortChange3",
    "searchSubmit",
    "searchReset",
]);

const activeRef = computed({
    get: () => props.active,
    set: (val) => {
        emits("update:active", val);
    }
});
const handleTabsChange = (tab) => {
    emits("tabsChange", tab);
};

const sortRef = computed({
    get: () => props.sort,
    set: (val) => {
        emits("update:sort", val);
    }
});
const sortRef2 = computed({
    get: () => props.sort2,
    set: (val) => {
        emits("update:sort2", val);
    }
});
const sortRef3 = computed({
    get: () => props.sort3,
    set: (val) => {
        emits("update:sort3", val);
    }
});
const handleSortChange = (val) => {
    emits("sortChange", val);
}
const handleSortChange2 = (val) => {
    emits("sortChange2", val);
}
const handleSortChange3 = (val) => {
    emits("sortChange3", val);
}


const fApi = ref({});
const searchRulesRef = ref(props.searchRules);
const searchOptions = {
    form: {
        inline: true,
        showMessage: false,
        // labelWidth: "auto",
        labelPosition: "left",
    },
    row: {gutter: 10},
    resetBtn: {
        show: true,
        click: () => {
            fApi.value.resetFields();
            emits("searchReset");
        }
    },
    onSubmit: (formData) => {
        emits("searchSubmit", formData);
    },
};
defineExpose({
    fApi
})
</script>