import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import formCreate from "@form-create/element-ui";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "element-plus/dist/index.css";
import "remixicon/fonts/remixicon.css";
import "./assets/style/normalize.css";
import "./assets/style/default.scss";
import components from "./components";

createApp(App).use(store).use(router).use(ElementPlus, {locale: zhCn}).use(formCreate).use(components).mount("#app");
