import {dayjs} from "element-plus";

const state = {
    host: "",
    token: null,
    expired: 0,
};

const getters = {
    hasToken: (state) => {
        return !(!state.token || !state.expired || dayjs().valueOf() >= state.expired);
    },
}

const mutations = {
    setToken: (state, value) => {
        state.host = value.host;
        state.token = {
            "key": "",
            "policy": value.policy,
            "OSSAccessKeyId": value.accessid,
            "success_action_status": 200,
            "signature": value.signature,
            "callback": value.callback,
            "dir": value.dir,
        };
        state.expired = value.expire * 1000;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};