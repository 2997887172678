import {ref} from "vue";
import {ElMessage} from "element-plus";

const msgRef = ref(null);

export const elMsg = (msg, type = "info", duration = 2000) => {
    if (msgRef.value) {
        msgRef.value.close();
    }
    msgRef.value = ElMessage({
        message: msg,
        type: type,
        duration: duration,
    })
}