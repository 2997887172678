<template>
    <div>
        <el-upload
            v-model:file-list="tempList"
            :action="actionUrl"
            :accept="accept"
            :drag="drag"
            :limit="limit"
            :multiple="multiple"
            :class="limited"
            :disabled="disabled"
            :list-type="listType"
            :before-upload="beforeUpload"
            :on-change="updateLimited"
		    :on-exceed="handleExceed"
            :on-success="handleSuccess"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-error="handleError"
        >
            <el-icon>
                <Plus />
            </el-icon>
        </el-upload>
        <el-dialog v-model="visible">
            <img width="100%" :src="imageUrl" alt="Preview Image" />
        </el-dialog>
    </div>
</template>
<script setup>
import {ref, computed, watch, nextTick} from "vue";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";
import {Plus} from "@element-plus/icons-vue";
const actionUrl = computed(() => process.env.VUE_APP_BASE_URL + '/api/upload/single')

const store = useStore();
const props = defineProps({
    modelValue: Array,
    drag: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    accept: {type: String, default: "image/*"},
    limit: Number,
    listType: {type: String, default: "picture-card"},
    class: String,
    style: String,
});
const emits = defineEmits([
    "update:modelValue",
    "success",
    "remove",
    "fc.el",
]);
const fileList = computed({
    get: () => {
        return props.modelValue
    },
    set: (val) => {
        emits("update:modelValue", val);
    }
});
const tempList = computed({
    get: () => {
        return props.modelValue.map((el, index) => {
            return {name: "name"+index,url: el};
        });
    },
    set: (val) => {
        return val;
    }
});
const filePath = ref("");
const limited = ref("");
const visible = ref(false);
const imageUrl = ref("");
const beforeUpload = (file) => {
    if (file.size > (1024 * 1024 * 10)) {
        ElMessage.error("上传图片大小不能超过 10MB!");
        return false;
    }
};
const handleExceed = (files, uploadFiles) => {
	if (files.length >= props.limit || fileList.value.length >= props.limit || (fileList.value.length + files.length) >= props.limit) {
		ElMessage.error(`最多上传${props.limit}张图片`)
	} else {
		ElMessage.error('上传图片错误，请重试')
	}
}
const handleSuccess = (res, file, files) => {
    console.log(files);
    if (files.every(item => item.status == 'success')) {
        const filePathList = files.filter(i => i.response).map(i => i.response.data);
        fileList.value = [...fileList.value, ...filePathList];
        emits("success", filePath.value, fileList.value);
      }
};
const updateLimited = () => {
    if (props.limit === 0) return;
    nextTick(()=>{
        limited.value = fileList.value.length >= props.limit ? "hide-trigger" : "";
    })
}
updateLimited();
const handleRemove = (file) => {
    console.log("uploadRemove", file.url);
    let temp = [...fileList.value];
    let index = temp.indexOf(file.url);
    if (index > -1) {
        temp.splice(index, 1);
        fileList.value = temp;
    }
    updateLimited();
    emits("remove", file.url, fileList.value);
};
const handleError = () => {
    ElMessage.error("上传失败，请重试！");
};
const handlePreview = (file) => {
    imageUrl.value = file.url;
    visible.value = true;
};
</script>
<style>
.el-upload--picture-card {
    margin: 0 8px 8px 0;
}
.hide-trigger .el-upload--picture-card{
    display: none;
}
</style>