// 保存
export const setStorage = (name, value) => {
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    localStorage.setItem(name, value)
}
// 获取普通类型
export const getItem = name => {
    return localStorage.getItem(name)
}
// 获取对象
export const getStorage = name => {
    const data = localStorage.getItem(name)
    try {
        return JSON.parse(data)
    } catch (err) {
        return data
    }
}
// 删除
export const delStorage = name => {
    localStorage.removeItem(name)
}
// 清空
export const clrStorage = () => {
    localStorage.clear()
}
/**
 * window.localStorage 浏览器永久缓存
 * @method setItem 设置缓存
 * @method getItem 获取缓存
 * @method removeItem 移除缓存
 * @method clear 移除全部缓存
 */
export const Storage = {
	setItem(key, value) {
		window.localStorage.setItem(key, JSON.stringify(value))
	},
	getItem(key) {
		let json = window.localStorage.getItem(key)
		return JSON.parse(json)
	},
	removeItem(key) {
		window.localStorage.removeItem(key)
	},
	clear() {
		window.localStorage.clear()
	}
}

/**
 * window.sessionStorage 浏览器临时缓存
 * @method setItem 设置缓存
 * @method getItem 获取缓存
 * @method removeItem 移除缓存
 * @method clear 移除全部缓存
 */
export const SessionStorage = {
	setItem(key, value) {
		window.sessionStorage.setItem(key, JSON.stringify(value))
	},
	getItem(key) {
		let json = window.sessionStorage.getItem(key)
		return JSON.parse(json)
	},
	removeItem(key) {
		window.sessionStorage.removeItem(key)
	},
	clear() {
		window.sessionStorage.clear()
	}
}
