<template>
<!--    <el-form :label-position="labelPosition">-->
    <el-form-item :label-width="labelWidth" >
        <template #label>
            <div class="flex-column">
                <div>{{label}}</div>
                <div class="desc">{{subLabel}}</div>
            </div>
        </template>
<!--        <div v-if="uploadNum > 0" class="flex gap wrap">-->
<!--            <el-upload-->
<!--                v-for="i of uploadNum"-->
<!--                v-model:file-list="multFileList[i]"-->
<!--                :key="`upload${i}`"-->
<!--                :action="action"-->
<!--                :limit="1"-->
<!--                :multiple="false"-->
<!--                accept="image/*"-->
<!--                list-type="picture-card"-->
<!--                :on-success="(response, uploadFile, uploadFiles) => handleUploadSuccess(response, uploadFile, uploadFiles, i)"-->
<!--                :on-preview="handlePictureCardPreview"-->
<!--                :on-remove="handleRemove"-->
<!--            >-->
<!--                <el-icon><Plus/></el-icon>-->
<!--            </el-upload>-->
<!--        </div>-->
        <el-upload
            v-model:file-list="fileList"
            :action="action"
            :limit="limit"
            :multiple="multiple"
            :accept="accept"
            list-type="picture-card"
            :on-success="handleUploadSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
        >
            <el-icon><Plus/></el-icon>
        </el-upload>
        <el-dialog v-model="dialogVisible">
            <img :src="dialogImageUrl" alt="Preview Image" />
        </el-dialog>
    </el-form-item>
<!--    </el-form>-->
</template>
<script setup>
import {computed, ref} from "vue";
import { Plus } from '@element-plus/icons-vue'

/**
 * multiple: 是否使用多upload组件
 * limit: 上传数量限制
 *
 */
const props = defineProps({
    modelValue: {type: Array, default: []},
    label: {type: String, default: ""},
    subLabel: {type: String, default: ""},
    labelWidth: {type: String, default: "auto"},
    // labelPosition: {type: String, default: "left"},
    action: {type: String, default: process.env.VUE_APP_BASE_URL + "/api/upload/single"},
    accept: {type: String, default: "image/*"},
    multiple: {type: Boolean, default: true},
    uploadNum: {type: Number, default: 1},
    limit: {type: Number, default: 0},
});
const emits = defineEmits([
    "update:modelValue",
]);

const fileList = computed({
    get: () => props.modelValue,
    set: (val) => {
        emits("update:modelValue", val)
    }
});
const multFileList = ref([]);
const dialogVisible = ref(false);
const dialogImageUrl = ref("");

const handleRemove = (uploadFile, uploadFiles) => {
    console.log(uploadFile, uploadFiles)
}

const handlePictureCardPreview = (uploadFile) => {
    dialogImageUrl.value = uploadFile.url
    dialogVisible.value = true
}


const handleUploadSuccess = (res, file, files, index) => {
    console.log("",res, file, files, index);
    // file.url = res.data;
}

</script>