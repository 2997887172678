// 组装动态路由
import {useStore} from "vuex";
import {setMenuList} from '@/utils/tools'
import { getUserPermission, permissionListApi } from '@/api/index'
export const makeAsyncRoutes = () => {
    const Store = useStore();
    return new Promise(async (resolve) => {
        let accessRoutes = []
        if (localStorage.getItem("STAFFDECPT")) {
            const res = localStorage.getItem("STAFFDECPT") == -1 ? await permissionListApi({ page: 1, size: 999 }) : await getUserPermission({ id: localStorage.getItem("STAFFDECPT") })
            const menusList = setMenuList(localStorage.getItem("STAFFDECPT") == -1 ? res.data.data : res.data);
            Store.dispatch("system/setRoutesAll", JSON.parse(JSON.stringify(localStorage.getItem("STAFFDECPT") == -1 ? res.data.data : res.data)));
            Store.dispatch("system/setRoutes", JSON.parse(JSON.stringify(menusList)));
            menusList.forEach(item => {
                if (item.component) {
                    accessRoutes.push({
                        path: item.path,
                        name: item.name,
                        component: () => import('@/views'+item.path),
                        meta: {
                            title: item.title,
                            location: [item.title],
                        },
                    })
                }
                if (item.children?.length > 0) {
                    item.children.forEach(childItem => {
                        if (childItem.children?.length > 0) {
                            childItem.children.forEach(child => {
                                accessRoutes.push({
                                    path: child.path,
                                    name: child.name,
                                    component: () => import('@/views'+child.path),
                                    meta: {
                                        title: child.title,
                                        location: [item.title, childItem.title, child.title],
                                    },
                                })
                            })
                        }
                        accessRoutes.push({
                            path: childItem.path,
                            name: childItem.name,
                            component: () => import('@/views'+childItem.path),
                            meta: {
                                title: childItem.title,
                                location: [item.title, childItem.title],
                            },
                        })
                    })
                }
                if (item.path === "/home") {
                    accessRoutes.push({
                        path: item.path,
                        name: item.name,
                        component: () => import('@/views'+item.path),
                        meta: {
                            title: item.title,
                            location: [item.title],
                        },
                    })
                }
            })
        }
        // console.log("accessRoutes", accessRoutes);
        resolve(accessRoutes)
    })
}